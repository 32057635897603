<template>
<div>
    <v-card flat :color="TemaDark?'background2':'primary'" height="50" class="card-bk rounded-0" width="100%" v-if="!skeleton"/>
    <v-row>
        <v-col cols="12">
            <v-card flat class="rounded-lg d-flex flex-column">
                <!-- Overlay -->
                <v-overlay :opacity=".6" :color="TemaDark?'grey darken-4':'white'" :value="overlay" z-index="1" class="rounded-lg" absolute />
                <v-card v-if="overlay" class="loader-overlay d-flex justify-center align-center" flat height="0px" width="22vh">
                    <v-row class="fill-height" align-content="center" justify="center">
                        <v-col class="subtitle-1 text-center pa-0" cols="12">
                            Cargando...
                        </v-col>
                        <v-col cols="6 pt-0">
                            <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- Toolbar -->
                <v-card flat class="rounded-lg pa-0 itinerario-title">
                    <v-toolbar flat v-if="!skeleton" class=" rounded-t-lg px-2">
                        <v-menu ref="menu" v-model="menu_mes" :close-on-content-click="false" right origin="top left" transition="scroll-x-transition" rounded="lg">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mr-2" :class="TemaDark?'primary':'secondary'" v-bind="attrs" v-on="on" style="margin-left: -8px;height: 42px;width: 42px;">
                                    <v-icon :color="TemaDark?'white':'primary'" size="20" class="icon_btn_picker">mdi-calendar</v-icon>
                                </v-btn>
                            </template>
                            <v-card flat class="rounded-lg">
                                <v-date-picker v-model="fecha" type="date" scrollable flat :show-current="true" no-title @change="$refs.menu.save(GetFecha(fecha, 0))" width="280" first-day-of-week="1" :min="rango.inicio" :max="rango.termino" show-adjacent-months>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="GetFecha(FechaActual, 0);menu_mes = false">Hoy</v-btn>
                                    <v-btn text color="primary" @click="menu_mes = false">Cerrar</v-btn>
                                </v-date-picker>
                            </v-card>
                        </v-menu>
                        <v-toolbar-title class="pa-0">
                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="headline mb-0">
                                        <v-toolbar-title class="pa-0 trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">
                                            {{FechaNumero}} de 
                                            <span class="text-capitalize">{{FechaMes}}</span>
                                        </v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium mb-0">
                                        <span class="text-capitalize">{{Nombredia}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn icon @click="GetFecha(fecha,-1)">
                            <v-icon color="primary" size="20">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon @click="GetFecha(fecha,1)">
                            <v-icon color="primary" size="20">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card>
                <div v-if="skeleton" class="rounded-t-lg">
                    <v-skeleton-loader type="list-item-avatar-two-line" />
                </div>
                <v-card class="pa-0 card-vista rounded-lg" flat id="itinerario_card">
                        <v-container class="pt-0">
                            <v-row>
                                <v-col cols="12" sm="6" class="px-0 pt-0">
                                    <v-list subheader class="pa-2" :key="refresh">
                                        <v-list-item class="text-center px-0 itinerario-via-title rounded-xl" v-if="!skeleton" style="min-height: 40px;" :color="TemaDark?'grey darken-4':'secondary'">
                                            <v-list-item-content class="py-0">
                                                <v-card outlined height="42" class="rounded-lg py-1 d-flex align-center justify-center" :color="TemaDark?'grey darken-3':'secondary'">
                                                    <span class="text-body-1 font-weight-medium " :class="TemaDark?'grey--text text--lighten-3':'primary--text '">
                                                        Servicios Bajando
                                                    </span>
                                                </v-card>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <div v-if="!skeleton" class="pt-3">
                                            <v-list-item :key="index" @click="DetalleServicio(servicio.numero, servicio.turno.id, servicio.inicio, servicio.termino,servicio.recorrido)" class="px-1 mb-1 rounded-lg" v-for="(servicio, index) in itinerario.v2" :class="servicio.activo?'ser-activo-v2': ''">
                                                <v-list-item-avatar class="my-0 mr-1">
                                                    <v-avatar :color="TemaDark?'primary':'secondary'" size="35" v-if="servicio.activo">
                                                        <span class="text-body-2 font-weight-medium turno-title" :class="TemaDark?'grey--text text--lighten-3':'primary--text'">{{servicio.numero}}</span>
                                                    </v-avatar>
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35" v-else>
                                                        <span class="text-body-2 font-weight-medium turno-title" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">{{servicio.numero}}</span>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title class="text-subtitle-2 red--text text-capitalize" v-if="servicio.maquinista == 'Sin Maquinista'">
                                                        {{servicio.maquinista}}
                                                    </v-list-item-title>
                                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-else>
                                                        {{servicio.maquinista}}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>{{FormatRecorrido(servicio.recorrido)}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <span class="text-subtitle-2 grey--text">{{servicio.inicio}} - {{servicio.termino}}</span>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </div>
                                        <div v-else>
                                            <v-skeleton-loader type="list-item-avatar" v-for="(item, index) in 10" :key="index" />
                                        </div>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="6" class="px-0 pt-0">
                                    <v-list subheader class="pa-2" :key="refresh">
                                        <v-list-item class="text-center px-0 itinerario-via-title rounded-xl" v-if="!skeleton" style="min-height: 40px;" :color="TemaDark?'grey darken-4':'secondary'">
                                            <v-list-item-content class="py-0">
                                                <v-card outlined height="42" class="rounded-lg py-1 d-flex align-center justify-center" :color="TemaDark?'grey darken-3':'secondary'">
                                                    <span class="text-body-1 font-weight-medium " :class="TemaDark?'grey--text text--lighten-3':'primary--text '">
                                                        Servicios Subiendo
                                                    </span>
                                                </v-card>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <div v-if="!skeleton" class="pt-3">
                                            <v-list-item :key="index" @click="DetalleServicio(servicio.numero, servicio.turno.id, servicio.inicio, servicio.termino, servicio.recorrido)" class="px-1 mb-1 rounded-lg" v-for="(servicio, index) in itinerario.v1" :class="servicio.activo?'ser-activo-v1': ''">
                                                <v-list-item-avatar class="my-0 mr-1">
                                                    <v-avatar :color="TemaDark?'primary':'secondary'" size="35" v-if="servicio.activo">
                                                        <span class="text-body-2 font-weight-medium turno-title" :class="TemaDark?'grey--text text--lighten-3':'primary--text'">{{servicio.numero}}</span>
                                                    </v-avatar>
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35" v-else>
                                                        <span class="text-body-2 font-weight-medium turno-title" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">{{servicio.numero}}</span>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title class="text-subtitle-2 red--text text-capitalize" v-if="servicio.maquinista == 'Sin Maquinista'">
                                                        {{servicio.maquinista}}
                                                    </v-list-item-title>
                                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-else>
                                                        {{servicio.maquinista}}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>{{FormatRecorrido(servicio.recorrido)}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <span class="text-subtitle-2 grey--text">{{servicio.inicio}} - {{servicio.termino}}</span>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </div>
                                        <div v-else>
                                            <v-skeleton-loader type="list-item-avatar" v-for="(item, index) in 10" :key="index" />
                                        </div>
                                    </v-list>
                                </v-col>
                                <v-fade-transition>
                                    <v-col cols="12" class="d-flex justify-center itinerario-active-btn" v-if="FechaHoy">
                                        <v-btn class="mx-1 " fab color="primary" depressed @click="ScrollServicioActivoV2()" :disabled="!UltimoTrenV1">
                                            <v-icon dark>
                                                mdi-chevron-down
                                            </v-icon>
                                        </v-btn>
                                        <v-btn class="mx-1 " fab color="primary" depressed @click="ScrollServicioActivoV1()" :disabled="!UltimoTrenV2">
                                            <v-icon dark>
                                                mdi-chevron-up
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-fade-transition>
                            </v-row>
                        </v-container>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog.itinerario_servicio" persistent max-width="450" :transition="DialogAnimation" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-card class="rounded-lg" max-height="90%">
            <v-toolbar flat height="62" class="rounded-t-lg">
                <v-list-item class="px-0">
                    <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                    <v-list-item-content>
                        <v-list-item-title class="trenos-title-toolbar mb-0">
                            <v-toolbar-title class="pa-0 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Servicio {{dialog_datos.servicio}}</v-toolbar-title>
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-medium mb-0">
                            <span>
                                {{FechaNumero}} de 
                                <span class="text-capitalize">{{FechaMes}}</span>
                            </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <!--<v-btn icon @click="dialog_servicio = false" class="mr-0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>-->
            </v-toolbar>
            <v-tabs centered v-model="vista" height="42" class="pb-2">
                <v-tab class="rounded-lg">Turno</v-tab>
                <v-tab class="rounded-lg">Recorrido</v-tab>
                <v-tab class="rounded-lg">Itinerario</v-tab>
            </v-tabs>
            <v-tabs-items v-model="vista">
                <v-tab-item eager transition="fade" reverse-transition="fade">
                    <Turno :in_dialog="true" :only_read="true" :id="turno_id" :dialog_btn="false" :vista="'itinerario'"/>
                </v-tab-item>
                <v-tab-item eager transition="fade" reverse-transition="fade">
                    <Recorrido :datos="servicio.dia" :inicio="servicio.inicio" />
                </v-tab-item>
                <v-tab-item eager transition="fade" reverse-transition="fade">
                    <ServicioItinerario :inicio="servicio_itinerario.inicio" :termino="servicio_itinerario.termino" :recorrido="servicio_itinerario.recorrido"/>
                </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseDialog('itinerario_servicio')" >
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import dayjs from "dayjs"
export default {
    components: {
        Turno: () => import('@/components/Turno'),
        Recorrido: () => import('@/components/Recorrido'),
        ServicioItinerario: () => import('@/components/ServicioItinerario')
    },
    name: 'Itinerario',
    data() {
        return {
            vista: 0,
            menu_mes: false,
            itinerario: {
                v1: null,
                v2: null
            },
            fecha: dayjs().format('YYYY-MM-DD'),
            overlay: false,
            dialog_servicio: false,
            turno_id: null,
            servicio: {
                dia: {}
            },
            dialog_datos: {
                servicio: null
            },
            skeleton: true,
            show_btn: false,
            check_activo: null,
            servicio_itinerario_datos:{
                v1: [
                    { nombre: 'Puerto', tiempo: 0},
                    { nombre: 'Miramar', tiempo: 14},
                    { nombre: 'El Belloto', tiempo: 40},
                    { nombre: 'S. Aldea', tiempo: 48},
                    { nombre: 'Limache', tiempo: 60},
                ],
                v2: [
                    { nombre: 'Limache', tiempo: 0},
                    { nombre: 'S. Aldea', tiempo: 12},
                    { nombre: 'El Belloto', tiempo: 20},
                    { nombre: 'Miramar', tiempo: 46},
                    { nombre: 'Puerto', tiempo: 60}
                ]
            },
            servicio_itinerario:{
                inicio: null,
                termino: null,
                recorrido: null, 
            },
            refresh: dayjs().unix()
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'sistema', 'update']),
        FechaNumero() {
            return dayjs(this.fecha).format('DD')
        },
        FechaMes(){
            return dayjs(this.fecha).format('MMMM')
        },
        FechaHoy() {
            let showbtn = false
            if (this.fecha == dayjs().format('YYYY-MM-DD') && this.show_btn) showbtn = true
            return showbtn
        },
        UltimoTrenV1() {
            let ultimo_tren = true
            if (this.itinerario.v1.length && this.itinerario.v1[this.itinerario.v1.length - 1].termino < dayjs().format('HH:mm:ss')) ultimo_tren = false
            return ultimo_tren
        },
        UltimoTrenV2() {
            let ultimo_tren = true
            if (this.itinerario.v2.length && this.itinerario.v2[this.itinerario.v2.length - 1].termino < dayjs().format('HH:mm:ss')) ultimo_tren = false
            return ultimo_tren
        },
        Nombredia() {
            return dayjs(this.fecha).format('dddd')
        },
        FechaActual() {
            return dayjs().format('YYYY-MM-DD')
        },
        UpdateComponente() {
            return this.update.itinerario
        },
        HoraSistema(){
            return this.sistema.hora
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(['ShowSnackBar', 'ErrorRequest','OpenDialog', 'CloseDialog']),
        //Carga el dialog con el detalle del servicio.
        async DetalleServicio(servicio_num, turno_id, inicio, termino, recorrido) {
            this.dialog_datos.servicio = servicio_num
            this.turno_id = turno_id
            this.OpenDialog('itinerario_servicio')
            this.vista = 0
            let fecha = dayjs(this.fecha).format('YYYYMMDD')
            await this.axios.get(`/itinerario/${fecha}/${servicio_num}`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.servicio.dia = respuesta.data
                    this.servicio.inicio = inicio
                    this.servicio_itinerario.inicio = inicio
                    this.servicio_itinerario.termino = termino
                    this.servicio_itinerario.recorrido = recorrido
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Genera el cambio de fecha y carga el itinerario correspondiente.
        GetFecha(fecha, operacion) {
            switch (operacion) {
                case 0: //cambiar mes
                    this.GetData(dayjs(fecha).format('YYYYMMDD'), this.usuario_id)
                    this.fecha = fecha
                    break
                case 1: //dia siguiente
                    this.GetData(dayjs(fecha).add(1, 'day').format('YYYYMMDD'), this.usuario_id)
                    this.fecha = dayjs(fecha).add(1, 'day').format('YYYY-MM-DD')
                    break
                case -1: //dia anterior
                    this.GetData(dayjs(fecha).subtract(1, 'day').format('YYYYMMDD'), this.usuario_id)
                    this.fecha = dayjs(fecha).subtract(1, 'day').format('YYYY-MM-DD')
                    break
            }
        },
        //Obtiene el itinerario desde la API
        async GetData(fecha) {
            this.turno = {}
            this.overlay = true
            await this.axios.get(`/itinerario/${fecha}`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.overlay = false
                    this.skeleton = false
                    this.itinerario = respuesta.data
                    this.rango = respuesta.data.rango
                    this.show_btn = true
                    this.ServicioActivo()
                    /*if (this.fecha == dayjs().format('YYYY-MM-DD')) {
                        this.ServicioActivo()
                        this.check_activo = setInterval(() => {
                            this.ServicioActivo()
                        }, 60000)
                    } else {
                        clearInterval(this.check_activo)
                    }*/
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Con base en la hora obtiene los servicio en via.
        ServicioActivo() {
            if (this.fecha == dayjs().format('YYYY-MM-DD')){
                let hora_sistema = dayjs().hour(this.sistema.hora.slice(0, -6)).minute(this.sistema.hora.slice(3,-3)).unix()
                for (let index = 0; index < this.itinerario.v1.length; index++) {
                    let servicio_inicio = dayjs().hour(this.itinerario.v1[index].inicio.slice(0, -3)).minute(this.itinerario.v1[index].inicio.slice(-2)).unix(),
                        servicio_termino = dayjs().hour(this.itinerario.v1[index].termino.slice(0, -3)).minute(this.itinerario.v1[index].termino.slice(-2)).unix()
                    if ((servicio_inicio <= hora_sistema) && (servicio_termino >= hora_sistema)) this.itinerario.v1[index].activo = true
                    else this.itinerario.v1[index].activo = false
                }
                for (let index = 0; index < this.itinerario.v2.length; index++) {
                    let servicio_inicio = dayjs().hour(this.itinerario.v2[index].inicio.slice(0, -3)).minute(this.itinerario.v2[index].inicio.slice(-2)).unix(),
                        servicio_termino = dayjs().hour(this.itinerario.v2[index].termino.slice(0, -3)).minute(this.itinerario.v2[index].termino.slice(-2)).unix()
                    if ((servicio_inicio <= hora_sistema) && (servicio_termino >= hora_sistema))  this.itinerario.v2[index].activo = true
                    else this.itinerario.v2[index].activo = false
                }
                this.refresh = dayjs().unix()
            } 
        },
        //Devuelve el icono correspondiente con base en el sentido del servicio.
        ServicioIcono(direccion) {
            let icono = null
            switch (direccion) {
                case 'Puerto - Limache':
                    icono = 'mdi-arrow-up'
                    break
                case 'Limache - Puerto':
                    icono = 'mdi-arrow-down'
                    break
                case 'Puerto - S. Aldea':
                    icono = 'mdi-arrow-up'
                    break
                case 'S. Aldea - Puerto':
                    icono = 'mdi-arrow-down'
                    break
                case 'Puerto - El Belloto':
                    icono = 'mdi-arrow-up'
                    break
                case 'El Belloto - Puerto':
                    icono = 'mdi-arrow-down'
                    break
                case 'S. Aldea - Miramar':
                    icono = 'mdi-arrow-down'
                    break
                case 'Portales - S. Aldea':
                    icono = 'mdi-arrow-up'
                    break
                case 'Limache - Baron':
                    icono = 'mdi-arrow-down'
                    break
            }
            return icono
        },
        //Mueve el itinerario hasta mostrar la lista de los servicios activos por v2
        ScrollServicioActivoV2() {
            const element = this.$el.getElementsByClassName('ser-activo-v2')[0]
            if (element) this.$vuetify.goTo('.ser-activo-v2', { offset: 115, duration: 0})
            else this.ErrorRequest({
                mensaje: 'Sin servicios por V2',
                status: 1
            })

        },
        //Mueve el itinerario hasta mostrar la lista de los servicios activos por v1
        ScrollServicioActivoV1() {
            const element = this.$el.getElementsByClassName('ser-activo-v1')[0]
            if (element) this.$vuetify.goTo('.ser-activo-v1', { offset: 115, duration: 0})
            else this.ErrorRequest({
                mensaje: 'Sin servicios por V1',
                status: 1
            })
        },
        //Devuelve el nombre completo del recorrido con base en sus siglas.
        FormatRecorrido(recorrido){
            let nombre_recorrido
            switch (recorrido) {
                case 'pu-li':
                    nombre_recorrido = 'Puerto - Limache' 
                    break
                case 'li-pu':
                    nombre_recorrido = 'Limache - Puerto' 
                    break
                case 'pu-sa':
                    nombre_recorrido = 'Puerto - S.Aldea' 
                    break
                case 'sa-pu':
                    nombre_recorrido = 'S.Aldea - Puerto' 
                    break
                case 'eb-pu':
                    nombre_recorrido = 'El Belloto - Puerto' 
                    break
                case 'pu-eb':
                    nombre_recorrido = 'Puerto - El Belloto' 
                    break
                case 'sa-mi':
                    nombre_recorrido = 'S.Aldea - Miramar' 
                    break
                case 'po-sa':
                    nombre_recorrido = 'Portales - S.Aldea' 
                    break
                case 'li-ba':
                    nombre_recorrido = 'Limache - Baron' 
                    break
                default:
                    break
            }
            return nombre_recorrido
        },
    },
    mounted() {
        window.scrollTo(0, 0)
        this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
    },
    destroyed: function () {
        clearInterval(this.check_activo)
    },
    watch:{
        UpdateComponente: function (val) {
            if (val) {
                this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
                this.update.itinerario = false
            }
        },
        HoraSistema: function (val) {
            if(val && val.slice(-2) == '00') this.ServicioActivo()
        }
    },
    created(){
        //Detecta si la webapp está visible o no
        /*document.addEventListener("visibilitychange", ()=>{
            if(document.visibilityState === 'visible') this.ServicioActivo()
        })
        */
        /*document.addEventListener('resume', (event) => {
            console.log('resume' + event)
            this.ServicioActivo()
        });*/
    }
}
</script>

<style scoped>
.theme--light.v-list-item:hover::before {
    opacity: 0.0;
}

.card-vista{
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}

@media only screen and (max-width: 960px) {
    .itinerario-title {
        position: sticky;
        top: calc(64px + env(safe-area-inset-top));
        background-color: white;
        z-index: 2;
    }

    .itinerario-via-title {
        position: sticky;
        top: calc(119px + env(safe-area-inset-top));
        background-color: white;
        z-index: 1;
    }
    
    .itinerario-active-btn {
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
        position: sticky;
        bottom: calc(60px + var(--safe-area-inset-bottom)) !important;
        z-index: 2;
    }
}

@media only screen and (min-width: 961px) {
    .itinerario-title {
        position: sticky;
        top: calc(64px + env(safe-area-inset-top));
        background-color: white;
        z-index: 2;
    }

    .itinerario-via-title {
        position: sticky;
        top: calc(126px + env(safe-area-inset-top));
        background-color: white;
        z-index: 2;
    }

    .itinerario-active-btn {
        position: sticky;
        bottom: calc(0px) !important;
        z-index: 2;
    }
}

.loader-overlay {
    background-color: transparent;
    position: sticky;
    top: 400px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 4;
}

.v-btn--fab.v-size--default {
    height: 48px;
    width: 48px;
}

.servicioactivo {
    background-color: var(--v-secondary-base);
}

.v-list-item {
    min-height: 46px;
}

.itinerario-col {
    z-index: 10;
}

.card-bk{
    position: fixed;
    top: 64px;
    z-index: 2;
}

.theme--light.v-list-item:focus::before {
    border-radius: 8px;
}
</style>
